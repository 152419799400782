import { Link } from 'gatsby'
import React from 'react'
import SEO from '../components/SEO'
import { NoIndex } from '../components/NoIndex'
import FeaturedIn from '../components/Homepage/FeaturedIn'
// Shared
import ConditionStats from '../components/Shared/ConditionStats'
import IntegrativeApproach from '../components/Shared/IntegrativeApproach'
import PersonaCarousel from '../components/Shared/PersonaCarousel'
import MemberResults from '../components/Shared/MemberResults'
import BoxAppCoach from '../components/Shared/BoxAppCoach'
import CaseStudies from '../components/Shared/CaseStudies'

// Heros
import goodpathHero from '../images/homepage/homepage-hero.png'
import homepageHero from '../images/homepage/homepage-mobile-hero.jpg'

const Community = ({ location }) => {
  return (
    <div>
      <NoIndex />
      <SEO
        title="Chronic condition care for nonprofit employees"
        location={location}
      />
      <div className="relative flex flex-col">
        {/* Hero */}
        <div className="relative bg-gp-violet lg:bg-gp-violet-10">
          <div className="flex items-center px-8 py-8 mx-auto lg:py-16 xl:py-28 xl:min-h-700 lg:max-w-7xl">
            <div className="flex flex-col items-start lg:max-w-xl">
              <h1 className="mb-6 text-4xl font-bold leading-tight lg:text-5xl text-gp-off-white lg:text-gp-violet">
                Giving back to the givers
              </h1>
              <p className="mb-6 text-lg leading-snug md:text-2xl text-gp-off-white lg:text-gp-violet">
                We're mission-driven, just like you, so we want to offer our
                care to nonprofits solely for what it costs us to deliver it.
              </p>

              <p className="mb-6 text-lg leading-snug md:text-2xl text-gp-off-white lg:text-gp-violet">
                Book a demo to get your employees relief for back &amp; joint
                pain, poor sleep, digestive issues, or long COVID.
              </p>
              <Link
                to="partner"
                className="inline-flex px-6 py-5 text-xl font-bold transition-all duration-300 rounded bg-gp-off-white lg:bg-gp-violet text-gp-violet lg:text-gp-off-white lg:text-2xl lg:px-7 lg:py-6 lg:min-h-24 hover:no-underline hover:bg-gp-violet-70 focus:no-underline focus:bg-gp-violet-70"
              >
                Request a demo
              </Link>
            </div>
          </div>
          <div className="">
            <img
              src={homepageHero}
              alt="A man in good health after completing his Goodpath program."
              className="w-full lg:hidden"
            />
          </div>
          <div className="absolute top-0 right-0 mt-8">
            <img
              src={goodpathHero}
              alt="Two women enjoying freedom from chronic pain, hiking through plains."
              className="hidden lg:block lg:max-w-lg xl:max-w-3xl"
            />
          </div>
        </div>

        {/* Statistics */}
        <ConditionStats />

        {/* Integrative Approach */}
        <IntegrativeApproach />

        {/* Persona Carousel */}
        <PersonaCarousel />

        {/* Member Results */}
        <MemberResults />

        {/* Mega section, box, app, coach */}
        <BoxAppCoach />

        {/* Case Studies */}
        <CaseStudies />

        {/* Featured In */}
        <FeaturedIn />
      </div>
    </div>
  )
}

export default Community
